<template>
  <div>
    <div class="message-name">
      <table style="width:100%;margin-bottom:3px;">
        <tbody>
          <tr>
              <th style="width: 100%;height:35px;text-align: center;border: 1px solid gray;">
                <img style="width:23px;" src="../../assets/logo.png" alt="">
                古树医院
              </th>
          </tr>
        </tbody>
      </table>
      <video v-if="id === '1156' || id === '1157'" style="width:100%;" ref="video" autoplay loop controls muted src="../../assets/taipingsi.mp4"></video>
      <table style="width:100%;margin-top:0px;">
        <tbody style="border-top: 1px solid gray;">
          <tr>
            <td class="td-only">古树编号</td>
            <td class="td-right" style="width: 50%;" > {{data.tree_num}}</td>
          </tr>
        </tbody>
      </table>
      <table style="width:100%;">
        <tbody>
          <tr>
            <td class="td-only">古树等级</td>
            <td class="td-right" style="width: 50%;" > {{data.level}}</td>
          </tr>
        </tbody>
      </table>
      <table style="width:100%;">
        <tbody v-if="data.category !== null">
          <tr>
            <td class="td-left" rowspan="5">树种</td>
            <td class="td-right">种名</td>
            <td class="td-right">{{data.category.name}}</td>
          </tr>
          <tr>
            <td class="td-right">科名</td>
            <td class="td-right">{{data.category.family}}</td>
          </tr>
          <tr>
            <td class="td-right">属名</td>
            <td class="td-right">{{data.category.genus}}</td>
          </tr>
          <tr>
            <td class="td-right">拉丁名</td>
            <td class="td-right">{{data.category.latin_name}}</td>
          </tr>
          <tr>
            <td class="td-right">别名</td>
            <td class="td-right">{{data.category.alias}}</td>
          </tr>
        </tbody>
        <tbody v-if="data.place !== null">
          <tr>
            <td class="td-left" rowspan="10">位置</td>
            <td class="td-right">省</td>
            <td class="td-right">{{data.place.province}}</td>
          </tr>
          <tr>
            <td class="td-right">市</td>
            <td class="td-right">{{data.place.city}}</td>
          </tr>
          <tr>
            <td class="td-right">县（区）</td>
            <td class="td-right">{{data.place.area}}</td>
          </tr>
          <tr>
            <td class="td-right">乡镇（街道）</td>
            <td class="td-right">{{data.place.street}}</td>
          </tr>
          <tr>
            <td class="td-right">村（居）</td>
            <td class="td-right">{{data.place.village}}</td>
          </tr>
          <tr>
            <td class="td-right">小地名</td>
            <td class="td-right">{{data.place.place_name}}</td>
          </tr>
          <tr>
            <td class="td-right">生长场所</td>
            <td class="td-right">{{data.rise_place}}</td>
          </tr>
          <tr>
            <td class="td-right">具体场所</td>
            <td class="td-right">{{data.tree_specific_site}}</td>
          </tr>
          <tr>
            <td class="td-right">经度</td>
            <td class="td-right">{{data.geo_longitude}}</td>
          </tr>
          <tr>
            <td class="td-right">纬度</td>
            <td class="td-right">{{data.geo_latitude}}</td>
          </tr>
        </tbody>
      </table>
      <table style="width:100%;">
        <tbody>
          <tr>
            <td class="td-only" style="width: 25%;">分布特点</td>
            <td class="td-right" style="width: 25%;" > {{data.distribution}}</td>
            <td class="td-only" style="width: 25%;">权属</td>
            <td class="td-right" style="width: 25%;" > {{data.ownership}}</td>
          </tr>
        </tbody>
      </table>
      <table style="width:100%;">
        <tbody>
          <tr>
            <td class="td-only" style="width: 25%;">树龄(年)</td>
            <td class="td-right" style="width: 25%;" >{{data.estimate_age}}</td>
            <td class="td-only" style="width: 25%;">树高(米)</td>
            <td class="td-right" style="width: 25%;" >{{data.height}}</td>
          </tr>
        </tbody>
      </table>
      <table>
       <tbody>
          <tr>
            <td class="td-left" rowspan="3">冠幅</td>
            <td class="td-right">平均冠幅(m)</td>
            <td class="td-right">{{data.average_crown_width}}</td>
          </tr>
          <tr>
            <td class="td-right">东西冠幅(m)</td>
            <td class="td-right">{{data.west_east_crown_width}}</td>
          </tr>
          <tr>
            <td class="td-right">南北冠幅(m)</td>
            <td class="td-right">{{data.south_north_crown_width}}</td>
          </tr>
        </tbody>
      </table>
      <table>
       <tbody>
          <tr>
            <td class="td-left" rowspan="5">立地条件</td>
            <td class="td-right">坡度</td>
            <td class="td-right">{{data.slope}}</td>
          </tr>
          <tr>
            <td class="td-right">坡位</td>
            <td class="td-right">{{data.slope_location}}</td>
          </tr>
          <tr>
            <td class="td-right">坡向</td>
            <td class="td-right">{{data.aspect}}</td>
          </tr>
          <tr>
            <td class="td-right">海拔</td>
            <td class="td-right">{{data.altitude}}</td>
          </tr>
          <tr>
            <td class="td-right">土壤类型</td>
            <td class="td-right">{{data.soil_type}}</td>
          </tr>
        </tbody> 
      </table>
      <table style="width:100%;">
        <tbody>
          <tr>
            <td class="td-only" style="width: 25%;">管护单位<br/>(个人)</td>
            <td class="td-right" style="width: 25%;" >{{data.protect_unit}}</td>
            <td class="td-only" style="width: 25%;">管护人</td>
            <td class="td-right" style="width: 25%;" >{{data.protect_person}}</td>
          </tr>
        </tbody>
      </table>
      <table style="width:100%;">
        <tbody>
          <tr>
            <td class="td-only" style="width: 25%;">保护现状</td>
            <td class="td-right" style="width: 25%;" >{{data.protect_status[0]}}</td>
            <td class="td-only" style="width: 25%;">养护复壮观状</td>
            <td class="td-right" style="width: 25%;" >{{data.rejuvenation_status[0]}}</td>
          </tr>
        </tbody>
      </table>
      <table style="width:100%;">
        <tbody>
          <tr>
            <td class="td-only">树木奇特性状描述</td>
            <td class="td-right" style="width: 50%;" >{{data.peculiar_feature_des}}</td>
          </tr>
        </tbody>
      </table>
      <table style="width:100%;">
        <tbody>
          <tr>
            <td class="td-only">影响生长环境因素</td>
            <td class="td-right" style="width: 50%;" >{{data.natural_factor}}</td>
          </tr>
        </tbody>
      </table>
      <!-- <div>中文名：{{data.category.name}}</div>
      <div>俗名：{{data.category.alias}}</div>
      <div>拉丁名：{{data.category.latin_name}}</div>
      <div>科：{{data.category.family}}</div>
      <div>属：{{data.category.genus}}</div> -->
      <!-- <div>生长场所：{{data.rise_place}}</div>
      <div>分布特点：{{data.distribution}}</div>
      <div>经度：{{data.geo_longitude}}</div>
      <div>纬度：{{data.geo_latitude}}</div>
      <div>位置：{{data.place.province}}{{data.place.city}}{{data.place.area}}{{data.place.street}}{{data.place.place_name}}</div>
      <div>权属：{{data.ownership}}</div>
      <div>特征代码：{{data.feature_code}}</div>
      <div>树龄：{{data.estimate_age}}</div>
      <div>古树等级：{{data.level}}</div>
      <div>平均冠幅：{{data.average_crown_width}}</div>
      <div>树高：{{data.height}}米</div> -->
      <!-- <div>海拔：{{data.altitude}}</div>
      <div>坡向：{{data.aspect}}</div>
      <div>坡度：{{data.slope}}</div>
      <div>坡位：{{data.slope_location}}</div> -->
      <!-- <div>土壤类型：{{data.soil_type}}</div> -->
      <!-- <div>管护单位（个人）：{{data.protect_unit}}</div>
      <div>管护人：{{data.protect_person}}</div>
      <div>保护现状：{{data.protect_status[0]}}</div>
      <div>养护复壮观状：{{data.rejuvenation_status[0]}}</div> -->
    </div>
    <div class="message-bottum-name">
      <div v-if="id === '1156' || id === '1157'" class="message-title">古树价值</div>
      <div v-if="id === '1156' || id === '1157'" class="message-bottum-title" style="margin-bottom:0;">&nbsp;禅寺多植树木，一为山门添景致，二为子孙树榜样。我国古代的高僧们独具慧眼，选银杏树来代替佛门圣树“菩提树”（道家称仙树）。银杏备受青睐，原因有二：一是银杏树生长缓慢，寿命极长，可达 3000年以上；二是银杏树形优美，春夏绿影婆娑，深秋金黄灿灿，具有极高的美学价值。</div>
      <div v-if="id === '1156' || id === '1157'" class="message-bottum-title" style="margin:0;">&nbsp;一棵古树，往往与一座城市、一个村镇、一段历史紧密相联，是有生命的历史证据，具有唯一性和不可复制性，一旦破坏，将会永远消失，无可挽回。大店村太平寺的两棵古银杏历经千年，见证了唐宋辉煌灿烂的文化，见证了明清时期的巍巍大气，也见证了中华儿女抵御外敌的不屈精神。</div>
      <div v-if="id === '1156' || id === '1157'" class="message-bottum-title" style="margin-top:0;">&nbsp;古树名木是祖先给后人留下的宝贵财富，是大自然的馈赠，更是珍贵的历史文化遗产。两棵古银杏不但是太平寺的奇观之一，也是大店村最显著的文化铭牌。</div>
      <div v-if="id === '1156' || id === '1157'" class="message-title">古树历史</div>
      <div v-if="id === '1156' || id === '1157'" class="message-bottum-title">&nbsp;胶州太平寺的两株千年“姐妹树”是唐太宗东征时的部下所植，见证了一个强大王朝的创立、兴盛和灭亡。</div>
      <!-- <div class="message-bottum-title">&nbsp;{{ data.history }}</div> -->
      <!-- <div class="message-title">树木奇特性状描述</div>
      <div class="message-bottum-title">&nbsp;{{ data.peculiar_feature_des }}</div> -->
      <!-- <div class="message-title">树种鉴定记载</div>
      <div class="message-bottum-title">&nbsp;{{ data.tree_classify }}</div> -->
      <!-- <div class="message-title">影响生长环境因素</div>
      <div class="message-bottum-title">&nbsp;{{ data.natural_factor }}</div> -->
      <div class="message-title">古树图像</div>
      <div class="message-bottum-title" style="text-align:center">
        <img style="width: 45%;margin: 0 10px 10px 0" v-for="(item, index) in data.tree_img_list" :key="index" :src="'/gsmm/api/v1/'+item.prefix + '/' + item.src">
      </div>
      <div class="message-bottum-title" v-if="data.tree_video !== null">
        <video style="width: 300px;" controls="controls" :src="'/gsmm/api/v1/'+data.tree_video.prefix + '/' + data.tree_video.src"></video>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'messageIndex',
  data() {
    return {
      data: {},
      id: '',
    };
  },
  created() {

  },
  mounted() {
    this.messageClick(this.$route.query.id)
  },
  methods: {
  async messageClick(id) {
    let that = this
    let uid = {id:id}
    // let uid = {id:'1157'}
    that.id = uid.id
    const res = await that.request.get('/tree/detail/' + uid.id)
    that.data = res.data
    console.log(res)
   },
  }
};
</script>

<style scoped lang='less'>
td {
  height: 29px;
}
.td-left {
  width: 13.35%;
  text-align: center;
  border: 1px solid gray;
  border-top:none;
}
.td-only {
  width: 50%;
  text-align: center;
  border: 1px solid gray;
  border-top:none;
}
.td-right {
  width: 20%;
  text-align: center;
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
}
.message-name {
  margin: 16px 22px;
  div {
    margin: 11px 0;
    font-size: 15px;
    font-family: monospace;
  }
}
.message-bottum-name {
  margin: 16px 22px;
  .message-title {
    font-size: 17px;
    font-weight: bold;
    color: #000000;
  }
  .message-bottum-title {
    margin: 6px 0;
    font-size: 15px;
    font-family: monospace;
  }
}

</style>
